const globalSearch = (
  searchValue, filteredData, allData, displayColumns, setSearchFilterTableData,
) => {
  const dataToSearchIn = filteredData || allData;
  const columnNames = displayColumns && displayColumns.map((column) => column.dataIndex);
  const data = [];
  for (let i = 0; i < dataToSearchIn.length; i += 1) {
    const value = dataToSearchIn[i];
    for (let j = 0; j < columnNames.length; j += 1) {
      const column = columnNames[j];

      if (value[column]?.toString()?.toLowerCase()
        ?.includes(searchValue.toString()?.toLowerCase())) {
        data.push(value);
        break;
      }
    }
  }
  setSearchFilterTableData([...data]);
};
const onRowSelect = (keys, rows, selectedRow, setSelectedRow) => {
  const rowObject = { ...selectedRow };
  rowObject.keys = keys;
  rowObject.rows = rows;
  setSelectedRow(rowObject);
};
export { globalSearch, onRowSelect };
