import React, { useState, useEffect, useContext } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import {
  listUserImportJob,
} from '../../Identity/Services';
import { Role } from '../../../models/index';
import MoreInfo from '../MoreInfo';
import * as invitesTabStyles from '../../../styles/admin/InviteTab.module.scss';
import { onRowSelect } from '../../Shared/Table/TableService';
import AdminContext from '../../../context/Admin/AdminContext';

function JobListingView(props) {
  const { identityProviderClient } = props;
  const { profileInfo } = useContext(AdminContext);
  const [tableData, setTableData] = useState([]);
  const [tableLoader, setTableLoader] = useState(false);
  const [selectedRow, setSelectedRow] = useState({ keys: [], rows: [] });

  const listUserImportJobs = async () => {
    setTableLoader(true);
    const jobs = await listUserImportJob(identityProviderClient);
    if (jobs?.UserImportJobs?.length !== 0) {
      const importJobTableData = jobs.UserImportJobs.map((job) => ({
        ...job,
        key: job.JobId,
      }));
      setTableData(importJobTableData);
    }
    setTableLoader(false);
  };
  /**
   * get users from cognito pool
   */
  const fetchSignedInUserDetails = async () => {
    if (profileInfo?.roles.includes(Role.ADMIN)
    || profileInfo?.roles.includes(Role.MANAGER)
  || profileInfo?.roles.includes(Role.OWNER)) {
      listUserImportJobs();
    } else {
      navigate('/', { replace: true });
    }
  };

  useEffect(() => {
    if (identityProviderClient) {
      fetchSignedInUserDetails();
    }
  }, [identityProviderClient]);

  const columns = [
    {
      title: 'Job Id',
      dataIndex: 'JobId',
      render: (JobId) => (<p className={invitesTabStyles.userName}>{JobId || ''}</p>),
    },
    {
      title: 'Job Start Date',
      dataIndex: 'StartDate',
      render: (date) => (
        <p className={invitesTabStyles.invitedOn}>{dayjs(date).format('DD/MM/YY')}</p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'Status',
    },
    {
      title: 'Imported Users',
      dataIndex: 'ImportedUsers',
    },
    {
      title: 'Skipped Users',
      dataIndex: 'SkippedUsers',
    },
    {
      title: 'Failed Users',
      dataIndex: 'FailedUsers',
    },
  ];

  const headers = columns.map((column) => ({ label: column.title, key: column.dataIndex }));

  const rowSelection = {
    selectedRowKeys: selectedRow?.keys,
    onChange: (keys, rows) => onRowSelect(keys, rows, selectedRow, setSelectedRow),
  };

  return (
    <div>
      <MoreInfo
        text="<p>The Status of import logs are listed here.</p>"
        mainContent={false}
        hideBanner={() => {}}
      />
      <div className={invitesTabStyles.invitesSentWrapper}>
        <div className={invitesTabStyles.heading}>
          <p className={invitesTabStyles.importLogs}>Import Logs</p>
          <CSVLink
            className={invitesTabStyles.downloadLink}
            data={selectedRow.rows.length ? selectedRow.rows : tableData}
            headers={headers}
            filename="Import Logs.csv"
          >
            Download

          </CSVLink>
        </div>
        <Table
          rowSelection={rowSelection}
          columns={columns}
          dataSource={tableData}
          pagination={{
            hideOnSinglePage: true,
            defaultPageSize: 10,
          }}
          className={invitesTabStyles.table}
          loading={tableLoader}
        />
      </div>
    </div>
  );
}
JobListingView.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default JobListingView;
